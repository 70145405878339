export default {
  clusters(state) {
    return state.clusters;
  },
  mission(state) {
    return state.mission;
  },
  timezone(state){
    return state.timezone;
  },
  videoPlaybackEncounter(state) {
    return state.videoPlaybackEncounter;
  },
  cameraFilters(state) {
    return state.cameraFilters;
  },
  attributeFilters(state) {
    return state.attributeFilters;
  },
  analyticsFilters(state) {
    return state.analyticsFilters;
  },
  watchlistMatchFilter(state) {
    return state.watchlistMatchFilter;
  },
  dtRangeFilter(state) {
    return state.dtRangeFilter;
  },
  allFaceAttributes(state) {
    return state.allFaceAttributes;
  },
  nameFilter(state) {
    return state.nameFilter;
  },
  sorting(state) {
    return state.sorting;
  },
  page(state) {
    return state.page;
  },
  moreToFetch(state) {
    return state.moreToFetch;
  },
  abortController(state) {
    return state.abortController;
  },
  associatesTargetCluster(state) {
    return state.associatesTargetCluster;
  },
  associatesTimeRangeFilter(state) {
    return state.associatesTimeRangeFilter;
  },
  associatesSameFrameFilter(state) {
    return state.associatesSameFrameFilter;
  },
  associatesFilterPopupFields(state) {
    return state.associatesFilterPopupFields;
  },
  isVideoPopupVisible(state) {
    return state.isVideoPopupVisible;
  },
  allClusters(state) {
    return state.allClusters;
  },
  watchlistsFilter(state) {
    return state.watchlistsFilter;
  },
  polFilter(state) {
    return state.polFilter;
  }
};
