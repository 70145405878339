function constructSameFrameFilter(encounters) {
  const filter = [];

  const second = 1000;
  for (let encounter of encounters) {
    // Sean 10-21-2024: Temporarily increasing this range per Christina's suggestion.

    const cameraId = encounter.track_info.cameraId;
    const startTimestamp = encounter.track_info.start_timestamp - second * 10;
    const stopTimestamp = encounter.track_info.stop_timestamp + second * 10;

    filter.push({
      cameraId: cameraId,
      timestamp: {
        start: startTimestamp,
        stop: stopTimestamp
      }
    });
  }

  return filter;
}

export { constructSameFrameFilter };
