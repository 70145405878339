import { DateTime } from "luxon";
export default class dateHelper {
  constructor() {}

  /**
   * Returns Date object representing the provided string.
   * @param {String} dateString
   * @returns Date
   */
  getDateFromDateTimeString(dateTimeString) {
    if (!dateTimeString) {
      return new Date();
    } else {
      return new Date(dateTimeString);
    }
  }

  /**
   * Returns Date object representing the provided string.
   * If Date only is provided, resulting Date will be UTC.
   * If ISO Date String is provided (YYYY-MM-DDTHH:mm:ss.sssZ), will parse into current time zone.
   * - this is intended for situations when a date only was saved into Mongo, which saves time as 00:00 (UTC)
   * @param {String} dateString
   * @returns Date
   */ 1;
  getDateFromDateOnlyString(dateString) {
    if (!dateString) {
      return new Date();
    } else {
      return new Date(
        dateString.length >= 16 ? dateString.substring(0, 16) : dateString
      );
    }
  }

  /**
   * Returns a human readable string representing the date and time with day of week.
   * @param {Date} date
   * @returns String
   */
  getDisplayDateTime(date) {
    return `${this.dayOfWeekAsString(date.getDay())} ${date.getMonth() +
      1}-${date.getDate()}-${date.getFullYear()}
      at ${date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      })}`;
  }

  /**
   * Returns a human readable string representing the date with day of week.
   * @param {Date} date
   * @returns String
   */
  getDisplayDate(date) {
    return `${this.dayOfWeekAsString(date.getDay())} ${date.getMonth() +
      1}-${date.getDate()}-${date.getFullYear()}`;
  }

  /**
   * Takes the date, returns it in a fromat of MM-DD-YYYY
   * Ex. 03/21/1999
   * @param {Date} date
   * @returns String
   */
  formatYYYYMMDD(date) {
    return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
  }

  dayOfWeekAsString(dayIndex) {
    return (
      [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ][dayIndex] || ""
    );
  }

  monthOfYearAsString(monthIndex) {
    return (
      [
        "January",
        "Febuary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ][monthIndex] || ""
    );
  }

  formatDateMinutes() {
    const d = new Date();
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let sec = d.getSeconds();
    const date = this.formatDate(d);

    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (sec.length < 2) sec = "0" + sec;

    return `${date}${hours}${minutes}${sec}`;
  }

  formatDate(d) {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("");
  }

  /**
   * Takes Intl timeZone name and returns UTC offset int
   * ex. America/Los_Angeles : -07:00
   * @param {String} tz
   * @returns {String} offsetValue
   */
  getUTCOffsetFromTimezoneName(tz) {
    const offset = new Intl.DateTimeFormat("en", {
      timeZone: tz,
      timeZoneName: "shortOffset"
    })
      .formatToParts()
      .find((part) => part.type === "timeZoneName").value;
    const offsetValue = offset.replace("GMT", "");
    // -6, -6:30 etc.

    let [hour, minute] = offsetValue.split(":");

    let sign = "+";
    if (hour[0] === "-") {
      sign = "-";
      hour = hour.slice(1);
    }

    if (!minute) {
      minute = "00";
    }

    let offsetString = `${sign}${hour.padStart(2, 0)}:${minute}`;

    return offsetString;
  }

  getCurrentTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getFullDatetimeStringFromLuxonDate(luxonDate) {
    const jsDate = luxonDate.toJSDate();

    return !isNaN(jsDate)
      ? jsDate.toLocaleString("en-US", { timeZone: luxonDate.zoneName }) +
          ` GMT${luxonDate.toFormat("ZZ")} (${luxonDate.zoneName})`
      : "Invalid date";
  }

  manuallyParseFilename(
    filename,
    splitString,
    orderSelection,
    occurenceSelection,
    cameraNameOrder,
    dateFormatString,
    timezone
  ) {
    let occurrenceSelectionIndices = [
      ...filename.matchAll(new RegExp(splitString, "gi"))
    ].map((a) => a.index);
    let splitIndex;
    if (orderSelection === "start") {
      splitIndex = occurrenceSelectionIndices[occurenceSelection - 1];
    } else {
      splitIndex =
        occurrenceSelectionIndices[
          occurrenceSelectionIndices.length - occurenceSelection
        ];
    }
    let cameraName;
    let restOfString;
    if (cameraNameOrder === "before") {
      cameraName = filename.substring(0, splitIndex);
      restOfString = filename.substring(
        splitIndex + splitString.length,
        filename.length
      );
    } else {
      cameraName = filename.substring(
        splitIndex + splitString.length,
        filename.length
      );
      restOfString = filename.substring(0, splitIndex);
    }
    // Sliding window, check for the format string within the string we need to parse.
    // Window length as large or less than the rest of the string (not including camera name)
    let luxonDate;
    for (
      let windowLength = 0;
      windowLength <= restOfString.length;
      windowLength++
    ) {
      for (let i = 0; i <= restOfString.length - windowLength; i++) {
        let checkString = restOfString.substring(i, windowLength);
        luxonDate = DateTime.fromFormat(checkString, dateFormatString, {
          zone: timezone
        });
        if (luxonDate.isValid) {
          break;
        }
      }
      if (luxonDate.isValid) {
        break;
      }
    }

    return [cameraName, luxonDate];
  }


  jsDateToLuxonObj(jsDate, zone){
    /*
     * Basically, the reason for this is because RocCalendar was returning a date string relative to the current system timezone.
     * We then re-parsed that date string via new Date(str) to get the unix timestamp for that date string. Which, in the context of post-event cases,
     * does not produce expected query results. So, in other words, the user THINKS they're filtering by a date
     * relative to the times they're seeing in the case but the timestamp produced will be different from the one they're expecting if the user is in a different timezone.
    *
    * Concretely:
    * The user is in NY and has uploaded a case from LA, and wants to look for encounters at 02/01/2024 10:09, we would've produced:
    *     new Date("02/01/2024 10:09 EDT").valueOf() -> 1706796540000
    * but we really wanted:
    *     new Date("02/01/2024 10:09 PDT").valueOf() -> 1706807340000
    * So, the solution (at least for now) is to parse our the date parts literally and create a new one relative to the LA timezone to get the correct timestamps.
    * Long term, the solution is probably for RocCalender to return just the literal date parts or a different date string format
    */
    const obj = {
      year: jsDate.getFullYear(),
      month: jsDate.getMonth() + 1,
      day: jsDate.getDate(),
      hour: jsDate.getHours(),
      minute: jsDate.getMinutes(),
      second: jsDate.getSeconds(),
      millisecond: jsDate.getMilliseconds()
    };
    return DateTime.fromObject(obj, { zone, });
  }

  //Convert date values to JS date object
  //if date is a unix timestamp (number), convert to JS date object using Luxon
  //otherwise, return the date object created from the string
  getJSDate(date, tz) {
    tz = tz || this.getCurrentTimezone();
    if (!date) return null;
    return typeof date === "number"
      ? DateTime.fromMillis(date).setZone(tz).toJSDate()
      : new Date(date);
  }
}
