<template>
  <div class="wrapper">
    <div id="roulette" ref="rouletteRef" @scroll="scrollHandler">
      <div
        v-for="(encounter, i) in encountersOnePerCluster"
        :key="encounter._id"
        ref="encounterRefs"
        class="encounter-card"
        @mouseover="hoveringOverEncounter[i] = true"
        @mouseleave="
          hoveringOverEncounter[i] = false;
          isEncounterMenuOpen[i] = false;
        "
      >
        <div :class="{ darken: hoveringOverEncounter[i] }">
          <auth-img
            :src="getEncounterThumbnail(encounter)"
            class="best-thumbnail"
            draggable="false"
          />
        </div>
        <Transition name="fade">
          <div v-if="hoveringOverEncounter[i]">
            <div class="date">
              <span>
                {{ formattedDateString(encounter.track_info.start_timestamp) }}
              </span>
              <span>
                {{ formattedTimeString(encounter.track_info.start_timestamp) }}
              </span>
            </div>
            <div class="menu">
              <MDBDropdown v-model="isEncounterMenuOpen[i]" class="menu">
                <RocDropdownToggle
                  @click="isEncounterMenuOpen[i] = !isEncounterMenuOpen[i]"
                >
                  <RocIcon
                    size="md"
                    icon="kebab"
                    rotate="90"
                    style="color: white;"
                  />
                </RocDropdownToggle>
                <RocDropdownMenu>
                  <RocDropdownItem
                    @click="
                      findClusterOfEncounter(encounter._id);
                      isEncounterMenuOpen[i] = false;
                    "
                  >
                    Find Cluster
                  </RocDropdownItem>
                </RocDropdownMenu>
              </MDBDropdown>
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <div class="buttons">
      <RocButton
        size="tiny"
        class="button"
        type="white"
        :disabled="currentEncounterIndex === 0"
        @click="skipPrevious"
      >
        <RocIcon icon="downArrow" size="xs" color="black" rotate90 />
      </RocButton>
      {{ currentEncounterIndex + 1 }} / {{ encountersOnePerCluster.length }}
      <RocButton
        size="tiny"
        class="button"
        type="white"
        :disabled="currentEncounterIndex === encountersOnePerCluster.length - 1"
        @click="skipNext"
      >
        <RocIcon icon="downArrow" size="xs" color="black" rotate270 />
      </RocButton>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { DateTime } from "luxon";

import RocButton from "@/components/ui/RocButton";
import RocIcon from "@/components/ui/RocIcon";

// Dropdown components
import { MDBDropdown } from "mdb-vue-ui-kit";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle.vue";
import RocDropdownItem from "@/components/ui/RocDropdownItem";

import { ref, watch, computed } from "vue";
import store from "../../store";

const props = defineProps({
  encounters: Array,
  encounterClusterLUT: Object,
  currentTimestamp: Number,
  videoStart: Number,
  ignoreCurrent: Boolean
});

const emit = defineEmits(["seek", "find-cluster"]);

const rouletteRef = ref();
const encounterRefs = ref([]);
const hoveringOverEncounter = ref([]);
const isEncounterMenuOpen = ref([]);

const encountersOnePerCluster = computed(() => {
  const output = [];
  const seenClusters = new Set();
  for (let encounter of props.encounters) {
    if (seenClusters.has(props.encounterClusterLUT[encounter._id])) {
      continue;
    }
    output.push(encounter);
    seenClusters.add(props.encounterClusterLUT[encounter._id]);
  }

  // Reactivity
  hoveringOverEncounter.value.push(false);
  isEncounterMenuOpen.value.push(false);

  return output;
});

const currentEncounterIndex = ref(0);

watch(
  () => props.currentTimestamp,
  (nv) => {
    if (ignore.value) {
      ignore.value = false;
      return;
    }

    const idx = encountersOnePerCluster.value.findLastIndex(
      (e) => e.track_info.start_timestamp <= nv
    );
    currentEncounterIndex.value = idx > 0 ? idx : 0;
  }
);

/** Automatically flip to appropriate card */
const scrollOptions = {
  inline: "start",
  block: "end"
};
if (window.chrome) {
  // Unfortunately, smooth scrollingIntoView doesn't work properly on Chrome.
  scrollOptions.behavior = "instant";
}

watch(currentEncounterIndex, (nv) => {
  const offsetLeft = encounterRefs.value[nv].offsetLeft;
  rouletteRef.value.scrollLeft = offsetLeft;
});

const ignore = ref(false);

function skipPrevious() {
  if (currentEncounterIndex.value === 0) {
    return;
  }
  currentEncounterIndex.value--;

  ignore.value = true;
  emit(
    "seek",
    encountersOnePerCluster.value[currentEncounterIndex.value].track_info
      .start_timestamp
  );
}

function skipNext() {
  if (
    currentEncounterIndex.value ===
    encountersOnePerCluster.value.length - 1
  ) {
    return;
  }
  currentEncounterIndex.value++;

  ignore.value = true;
  emit(
    "seek",
    encountersOnePerCluster.value[currentEncounterIndex.value].track_info
      .start_timestamp
  );
}

function formattedDateString(timestamp) {
  const tz = store.getters['clusters/timezone'];
  const date = DateTime.fromMillis(timestamp, {zone: tz});
  const formatted = date.toFormat("DD");
  return formatted;
}

function formattedTimeString(timestamp) {
  const tz = store.getters['clusters/timezone'];
  const date = DateTime.fromMillis(timestamp, { zone: tz })
  const formatted = date.toFormat("HH:mm:ss");
  return formatted;
}

function findClusterOfEncounter(id) {
  emit("find-cluster", props.encounterClusterLUT[id]);
}

function getEncounterThumbnail(encounter) {
  // If props.cluster.modality doesn't exist or it is equal to face, then use the face template id, otherwise object
  if (encounter?.facetemplateId) {
    const faceTemplateId = encounter.facetemplateId;
    return `/rest/v1/image/face/${faceTemplateId}/tn`;
  } else {
    const objectTemplateId = encounter.objtemplateId;
    return `/rest/v1/image/object/${objectTemplateId}/tn`;
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  overflow: hidden;
  gap: var(--spacing-s);
}

#roulette {
  display: flex;
  gap: 10px;
  overflow-x: hidden;
  width: 100%;
  scroll-behavior: smooth;
}

.encounter-card {
  border: solid 1px var(--overwatch-accent);
  position: relative;

  .date {
    @include overwatch-body-small;
    display: flex;
    flex-direction: column;

    position: absolute;
    top: var(--spacing-base);
    left: var(--spacing-base);
    color: white;
  }

  .menu {
    position: absolute;
    top: var(--spacing-base);
    right: var(--spacing-base);
  }
}

.encounter-card:first-child {
  margin-left: var(--spacing-l);
}
.encounter-card:last-child {
  margin-right: var(--spacing-l);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.darken {
  filter: brightness(80%);
  transition: filter 0.2s;
}

.menu :deep(ul) {
  // Menu positioning
  transform: translate(-130px, 40px) !important;
}

.best-thumbnail {
  height: 250px;
  width: auto;
}

.buttons {
  display: flex;
  align-self: center;
  gap: var(--spacing-s);
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-s);
}

.button {
  padding-left: var(--spacing-s);
  padding-right: var(--spacing-s);
}
</style>
