
// This performance sensitive function is designed to modify 'current' in-place.
function unionAttributes(current, additional) {
  if (!additional) return;

  let modified = false;
  for (const attribute of additional) {
    if (!current.includes(attribute)) {
      current.push(attribute);
      modified = true;
    }
  }

  if (modified) {
    current.sort((a, b) =>
      a.localeCompare(b, undefined, { sensitivity: "base" })
    );
  }
}

export default {
  resetState(state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setEncounters(state, payload) {
    state.encounters = payload;
  },
  appendEncounter(state, payload) {
    state.encounters.push(payload);
  },
  setSearchTemplateId(state, templateId) {
    state.searchTemplateId = templateId;
  },
  setSearchImageData(state, payload) {
    state.searchImageData = payload;
  },
  setSearchModality(state, modality) {
    state.searchModality = modality;
  },
  setEncounterFilterState(state, isActive) {
    state.encounterFilterState = isActive;
  },
  setEncounterDateRangeFilter(state, filter) {
    state.dtFilterRange = filter;
  },
  updateEncounter(state, socketIOData) {
    const type = socketIOData.type ?? socketIOData.objectType;

    // there may be more than one encounter from this personId if they matched multiple watchlists or watchlisted elements
    for (let i = 0; i < state.encounters.length; i++) {
      const encounter = state.encounters[i];
      // check personId to confirm encounter on the same track
      const samePersonId = encounter.personId === socketIOData.personId;
      // if match, check watchlistId to confirm this is the same match
      let sameMatch;
      if (type === "face_match") {
        sameMatch =
          encounter._watchlistId === socketIOData._watchlistId &&
          encounter.watchlistedFaceId === socketIOData.watchlistedFaceId;
      } else if (type === "object_lp_match") {
        sameMatch =
          encounter._watchlistId === socketIOData._watchlistId &&
          encounter.watchlistedLicensePlateId ===
            socketIOData.watchlistedLicensePlateId;
      } else {
        sameMatch = true;
      }
      if (samePersonId && sameMatch) {
        Object.assign(state.encounters[i], socketIOData);
      }
    }

    // update known attributes
    if (type === "object" || type === "object_lp_match") {
      unionAttributes(state.allObjectAttributes, socketIOData.attributes);
    } else if (type === "face" || type === "face_match") {
      unionAttributes(state.allFaceAttributes, socketIOData.attributes);
    }
  },
  upsertEvent(state, socketIOData) {
    const encIndex = state.encounters.findIndex(
      (encounter) => encounter.personId === socketIOData.personId
    );
    if (encIndex >= 0) {
      const updatedEncounter = {
        //id: socketIOData.id, //DO NOT update id, its used as the key in the dom, will cause issues
        probe_tn: socketIOData.probe_tn,
        cameraName: socketIOData.cameraName,
        cameraId: socketIOData.cameraId,
        templateId: socketIOData.templateId,
        timestamp: socketIOData.timestamp,
        objectType: socketIOData.type,
        md: socketIOData.md,
        attributes: socketIOData.attributes
      };

      if (socketIOData.type === "object") {
        updatedEncounter.label = socketIOData.label;
      }

      Object.assign(state.encounters[encIndex], updatedEncounter);
    } else {
      if (!state.liveFeedEnabled) {
        console.warn(`Live feed disabled, ignoring new encounter`);
        return;
      }
      if (state.matchesOnly) {
        console.warn(`Live feed is Matches Only, ignoring new encounter`);
        return;
      }
      const encounter = {
        id: socketIOData.id,
        cameraId: socketIOData.cameraId,
        personId: socketIOData.personId,
        timestamp: Number(socketIOData.timestamp),
        cameraName: socketIOData.cameraName,
        probe_tn: socketIOData.probe_tn,
        templateId: socketIOData.templateId,
        objectType: socketIOData.type,
        md: socketIOData.md,
        attributes: socketIOData.attributes
      };
      if (socketIOData.type === "object") {
        encounter.label = socketIOData.label;

        if (state.toastModalities?.includes(encounter.label)) {
          state.showWarningToast = encounter.label;
        }
        if(state.detectionAudioAlerts.includes(encounter.label))
        {
          state.audioToPlay = encounter.label;
        }
      }
      state.encounters.unshift(encounter);
    }

    if (state.encounters.length > state.maxLiveEncounters) {
      state.encounters.pop();
    }

    // update known attributes
    if (
      socketIOData.type === "object" ||
      socketIOData.type === "object_lp_match"
    ) {
      unionAttributes(state.allObjectAttributes, socketIOData.attributes);
    } else if (
      socketIOData.type === "face" ||
      socketIOData.type === "face_match"
    ) {
      unionAttributes(state.allFaceAttributes, socketIOData.attributes);
    }
  },
  bulkUpdateEncounter(state, payload) {
    if (payload) {
      const updateTargets = payload.encounters;
      delete payload.encounters; //remove the targets
      updateTargets.forEach((targetEncounterPersonId) => {
        const encIndex = state.encounters.findIndex(
          (encounter) => encounter.personId === targetEncounterPersonId
        );
        if (encIndex >= 0) {
          Object.assign(state.encounters[encIndex], payload);
        }
      });
    }
  },
  upsertAlarm(state, socketIOData) {
    const encounter = {};

    if (socketIOData.objectType === "face_match") {
      Object.assign(encounter, {
        id: socketIOData._id,
        cameraId: socketIOData.cameraId,
        timestamp: socketIOData.timestamp,
        cameraName: socketIOData.probeFaceCameraName,
        probe_tn: socketIOData.probeFaceTN,
        candidate_tn: socketIOData.tn,
        templateId: socketIOData.probeFaceTemplateId,
        objectType: "face_match",
        lastname: socketIOData.lastname,
        firstname: socketIOData.firstname,
        similarity: socketIOData.similarity,
        watchlistName: socketIOData.watchlistName,
        personId: socketIOData.personId,
        _watchlistId: socketIOData._watchlistId,
        md: socketIOData.md,
        attributes: socketIOData.attributes,
        watchlistedFaceId: socketIOData.watchlistedFaceId
      });

      // update known attributes
      unionAttributes(state.allFaceAttributes, socketIOData.attributes);
    } else if (socketIOData.objectType === "object_lp_match") {
      Object.assign(encounter, socketIOData);
      Object.assign(encounter, {
        id: socketIOData._id,
        cameraId: socketIOData.cameraId
      });
      // update known attributes
      unionAttributes(state.allObjectAttributes, socketIOData.attributes);
    }

    if (state.encounters.length > state.maxLiveEncounters) {
      state.encounters.pop();
    }

    const existingIndex = state.encounters.findIndex((encounter) => {
      const samePersonId = encounter.personId === socketIOData.personId; // check personId to confirm encounter on the same track
      const sameWatchlistId =
        encounter._watchlistId === socketIOData._watchlistId; // check watchlistId, this creates a new alarm encounter if on a different watchlist
      const previouslyUnknown = samePersonId && !encounter._watchlistId; // if personId previously encountered without a _watchlistId, it was unknown until now
      let sameMatchCandidate = false;
      if (socketIOData.objectType === "face_match") {
        sameMatchCandidate =
          encounter.watchlistedFaceId === socketIOData.watchlistedFaceId; // update on the same match
      } else if (socketIOData.objectType === "object_lp_match") {
        sameMatchCandidate =
          encounter.watchlistedLicensePlateId ===
          socketIOData.watchlistedLicensePlateId; // update on the same match
      }
      const updatedAlarm =
        samePersonId && sameMatchCandidate && sameWatchlistId; // updating a current alarm with new data
      return updatedAlarm || previouslyUnknown;
    });

    if (existingIndex === -1) {
      if (!state.liveFeedEnabled) {
        console.warn(`Live feed disabled, ignoring new match`);
        return;
      }
      state.encounters.unshift(encounter);
    } else {
      state.encounters[existingIndex] = encounter; //
    }
  },
  setShowImageSearchBox(state, payload) {
    state.showImageSearchBox = payload;
  },
  setSearchText(state, payload) {
    state.searchText = payload;
  },
  setCursor(state, cursorObj) {
    state.cursor = cursorObj;
  },
  setMoreData(state, hasMoreData) {
    state.moreData = hasMoreData;
  },
  setLiveFeedCameras(state, cameras) {
    state.liveFeedCameras = cameras;
  },
  setCameraLiveFeedState(state, controlState) {
    state.cameraLiveFeedState = controlState;
  },
  setCameraLiveFeedMode(state, mode) {
    state.cameraLiveFeedMode = mode;
  },
  setMatchesOnly(state, matchesOnlyEnabled) {
    state.matchesOnly = matchesOnlyEnabled;
  },
  deleteEncounter(state, personId) {
    const existingIndex = state.encounters.findIndex(
      (encounter) => encounter.personId === personId
    );
    if (existingIndex >= 0) {
      state.encounters.splice(existingIndex, 1);
    }
  },
  setUXProperty(state, payload) {
    const index = state.encounters.findIndex(
      (encounter) => encounter.id === payload.id
    );
    if (index >= 0) {
      const encounter = state.encounters[index];
      if (encounter) {
        if (!encounter.UXProps) {
          encounter.UXProps = {};
        }
        Object.assign(encounter.UXProps, payload.prop);
      }
    }
  },
  setAdjudicationFilter(state, payload) {
    state.adjudicationFilter = payload;
  },
  setAttributesFilter(state, payload) {
    state.attributesFilter = payload;
  },
  setAllFaceAttributes(state, payload) {
    state.allFaceAttributes = payload;
  },
  unionAllFaceAttributes(state, payload) {
    unionAttributes(state.allFaceAttributes, payload);
  },
  setAllObjectAttributes(state, payload) {
    state.allObjectAttributes = payload;
  },
  unionAllObjectAttributes(state, payload) {
    unionAttributes(state.allObjectAttributes, payload);
  },
  setAnalyticsFilter(state, payload) {
    state.analyticsFilter = payload;
  },
  setLiveFeedEnabled(state, payload) {
    state.liveFeedEnabled = payload;
  },
  setMaxLiveEncounters(state, payload) {
    state.maxLiveEncounters = payload;
  },
  setQueryPageSize(state, payload) {
    state.queryPageSize = payload;
  },
  setWarningToast(state,payload){
    state.showWarningToast = payload;
  },
  setDetectionAudioAlertsAlert(state, payload){
    state.detectionAudioAlerts = payload;
  },
  setToastModalities(state,payload){
    state.toastModalities = payload;
  },
  setToastModalityTimeout(state,payload){
    state.toastTimeout = payload;
  },
  setAudioToPlay(state,payload){
    state.audioToPlay = payload;
  }
};
