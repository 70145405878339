<script setup>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import RocButton from "@/components/ui/RocButton";
import { DateTime } from "luxon";
import DateHelper from "@/js/dateHelper";
import CaseFilenameParseExample from "../CaseFilenameParseExample.vue";
import BaseDialog from "@/components/ui/BaseDialog";

const store = useStore();

const dh = new DateHelper();

const rwwSettings = ref(store.getters["auth/userConfig"]?.rwwSettings ?? {});

const props = defineProps({
  exampleFilename: {
    type: String,
    required: false
  },
  timezone: {
    type: String,
    default: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
});

const emit = defineEmits(["apply"]);

const splitString = ref(
  rwwSettings.value?.newCaseDateTimeParser?.splitString ?? ".video."
);
const dateFormatString = ref(
  rwwSettings.value?.newCaseDateTimeParser?.dateFormatString ??
    "yyyyMMdd-HH.mm.ss"
);
const occurrenceSelection = ref(
  rwwSettings.value?.newCaseDateTimeParser?.occurrenceSelection ?? 1
);
const occurrenceOptions = ref([
  { text: "first", value: 1 },
  { text: "second", value: 2 },
  { text: "third", value: 3 }
]);
const orderSelection = ref(
  rwwSettings.value?.newCaseDateTimeParser?.orderSelection ?? "start"
);
const orderOptions = ref([
  { text: "start", value: "start" },
  { text: "end", value: "end" }
]);
const cameraNameOrder = ref(
  rwwSettings.value?.newCaseDateTimeParser?.cameraNameOrder ?? "before"
);
const cameraNameOrderOptions = ref([
  { text: "before", value: "before" },
  { text: "after", value: "after" }
]);

watch(
  () => store.getters["auth/userConfig"],
  (nv) => {
    if (nv) {
      rwwSettings.value = nv.rwwSettings;
      if (rwwSettings.value?.newCaseDateTimeParser) {
        const parseObject = rwwSettings.value.newCaseDateTimeParser;
        splitString.value = parseObject.splitString;
        dateFormatString.value = parseObject.dateFormatString;
        occurrenceSelection.value = parseObject.occurrenceSelection;
        orderSelection.value = parseObject.orderSelection;
        cameraNameOrder.value = parseObject.cameraNameOrder;
      }
    }
  }
);

const exampleCameraName = ref("");
const exampleStartDatetime = ref("");

watch(
  [
    splitString,
    occurrenceSelection,
    orderSelection,
    dateFormatString,
    cameraNameOrder
  ],
  () => {
    try {
      exampleParse();
    } catch {}
  },
  { immediate: true }
);

function apply() {
  let selectedDateTimeParser = {
    splitString: splitString.value,
    dateFormatString: dateFormatString.value,
    occurrenceSelection: occurrenceSelection.value,
    orderSelection: orderSelection.value,
    cameraNameOrder: cameraNameOrder.value
  };

  store.commit("cases/setDateTimeParser", selectedDateTimeParser);
  store.dispatch("saveUserConfig");

  emit("apply", selectedDateTimeParser);
}

// Just for example camera name and datetime.
function exampleParse() {
  const [cameraName, luxonDate] = dh.manuallyParseFilename(
    props.exampleFilename,
    splitString.value,
    orderSelection.value,
    occurrenceSelection.value,
    cameraNameOrder.value,
    dateFormatString.value,
    props.timezone
  );
  exampleCameraName.value = cameraName;
  exampleStartDatetime.value = dh.getFullDatetimeStringFromLuxonDate(luxonDate);
}

const isShowingExplanation = ref(false);
function openExplanation() {
  isShowingExplanation.value = true;
}
</script>

<template>
  <div class="manual-parser">
    <!-- splitString, occurrenceSelection, dateFormatString -->
    <div class="input-row">
      Split filename into camera name and datetime by:
      <input
        class="inline overwatch-body-med"
        style="width: 150px; margin-top: 10px;padding: 12px; border-radius: 5px;"
        v-model="splitString"
        title="Split string"
      />
    </div>
    <div class="input-row">
      Use its
      <select
        v-model="occurrenceSelection"
        class="overwatch-body-med occurrence"
        style="padding: 12px; border-radius: 5px;"
      >
        <option v-for="o in occurrenceOptions" :value="o.value">
          {{ o.text }}
        </option>
      </select>
      occurrence from the
      <select
        v-model="orderSelection"
        class="overwatch-body-med occurrence"
        style="padding: 12px; border-radius: 5px;"
      >
        <option v-for="o in orderOptions" :value="o.value">
          {{ o.text }}
        </option>
      </select>
      of the filename.
    </div>
    <div class="input-row">
      The datetime format is
      <input class="inline" v-model="dateFormatString" /> .
    </div>
    <div class="input-row">
      The camera name is
      <select
        v-model="cameraNameOrder"
        class="overwatch-body-med occurrence"
        style="padding: 12px; border-radius: 5px;"
      >
        <option v-for="o in cameraNameOrderOptions" :value="o.value">
          {{ o.text }}
        </option>
      </select>
      the datetime.
    </div>
    <div
      class="input-row"
      style="margin-top: var(--spacing-l);"
      v-if="exampleFilename"
    >
      For filename {{ exampleFilename }}
      <div>Example camera name: {{ exampleCameraName }}</div>
      <div>Example datetime: {{ exampleStartDatetime }}</div>
    </div>
    <div
      class="input-row"
      style="display: flex; margin-top: var(--spacing-l); align-items: center;"
    >
      <a @click="openExplanation">Explanation</a>
      <RocButton @click="apply" style="margin-left: auto;">
        Apply to Unparsed Files
      </RocButton>
    </div>
    <BaseDialog
      :show="isShowingExplanation"
      @close="isShowingExplanation = false"
    >
      <CaseFilenameParseExample />
    </BaseDialog>
  </div>
</template>

<style scoped lang="scss">
.manual-parser {
  @include overwatch-body-med;
  color: var(--overwatch-neutral-100);
  padding: var(--spacing-m);
}
select {
  display: inline-block;
  border: 1px solid var(--overwatch-neutral-100);
  width: 100px;
}
.input-row {
  width: 100%;
  margin-bottom: var(--spacing-base);
}
input {
  margin-top: 10px;
  padding: 12px;
  border-radius: 5px;
}

a {
  text-decoration: underline !important;
  cursor: pointer;
  color: var(--overwatch-primary) !important;
}
</style>
