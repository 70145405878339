<template>
  <div class="number-increment-rectangle" :class="{ disabled: disabled }">
    <input
      ref="inputRef"
      class="input-field"
      autocomplete="off"
      :disabled="disabled"
      @blur="formatInput()"
      @click="selectAllText()"
      v-model="numberValue"
      placeholder="00"
      :maxlength="max.length"
    />
    <div class="d-flex flex-column" style="gap: 4px;">
      <RocIcon
        color="primary"
        size="xs"
        icon="inputArrow"
        @click="disabled ? null : incrementNum()"
        style="cursor:pointer;"
      />
      <RocIcon
        color="primary"
        size="xs"
        icon="inputArrow"
        @click="disabled ? null : decrementNum()"
        style="cursor:pointer; transform: rotate(180deg);"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import RocIcon from "./RocIcon.vue";

export default {
  name: "RocNumberInput",
  components: {
    RocIcon
  },
  emits: ["number-input-change", "update:modelValue"],
  props: {
    modelValue: {
      // Sean: Supporting v-model
      type: [Number, String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 59
    },
    numberInput: {
      type: [Number, String],
      default: 0
    },
    inputType: {
      type: String,
      default: "number",
      notes:
        '"time" or "number" currently supported. "time" will emit a string (to enable leading zeroes in display), "number" will emit a number (no leading zeroes).'
    }
  },
  setup(props, context) {
    const numberValue = ref(
      props.modelValue ? props.modelValue : props.numberInput
    );
    const inputRef = ref(null);

    onMounted(() => {
      formatInput();
    });

    watch(
      () => props.numberInput,
      (newValue) => {
        numberValue.value = newValue;
      }
    );

    //select all input text on click for input element, that way user can type over existing text quickly
    function selectAllText() {
      if (inputRef.value) {
        inputRef.value.select();
      }
    }

    //increment works with the up caret onClick event in the timeInput field
    function incrementNum() {
      if (numberValue.value < props.max) {
        numberValue.value++;
      } else {
        numberValue.value = props.min;
      }
      formatInput();
    }

    //decrement works with the down caret onClick event in the timeInput field
    function decrementNum() {
      if (numberValue.value > props.min) {
        numberValue.value--;
      } else {
        numberValue.value = props.max;
      }
      formatInput();
    }

    //formats input of the time input fields
    function formatInput() {
      let stringTime = numberValue.value.toString();
      //if value is greater than max or less than min, set to 00
      if (numberValue.value > props.max || numberValue.value < props.min) {
        numberValue.value = "00";
      }
      //handling the input of single digit numbers, and adding a leading zero to them
      if (
        numberValue.value &&
        numberValue.value < 10 &&
        numberValue.value !== "00" &&
        stringTime.charAt(0) !== "0"
      ) {
        numberValue.value = "0" + numberValue.value;
      }
      //if value is 0, set to 00
      else if (numberValue.value === 0 || numberValue.value === "0") {
        numberValue.value = "00";
      }
      //if value is not a number
      else if (isNaN(numberValue.value)) {
        numberValue.value = "00";
      }

      if (props.inputType === "number") {
        numberValue.value = parseInt(numberValue.value);
      }
      //convert back to number type emit the value to the parent component
      context.emit("number-input-change", numberValue.value);
      context.emit("update:modelValue", numberValue.value);
    }

    return {
      selectAllText,
      numberValue,
      incrementNum,
      decrementNum,
      formatInput,
      inputRef
    };
  }
};
</script>

<style scoped lang="scss">
.number-increment-rectangle {
  box-sizing: border-box;
  height: 45px;
  width: 60px;
  border-radius: 5px;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);

  display: flex;
  align-items: center;
  padding: 5px;
  gap: 5px;
}

.number-increment-rectangle.disabled {
  background-color: var(--overwatch-neutral-400);

  input {
    color: var(--overwatch-neutral-300);
    background-color: var(--overwatch-neutral-400);
  }

  svg {
    color: var(--overwatch-neutral-300);
  }
}

.input-field {
  width: 100%;
  border: 0px;
  margin: 0 auto;
  text-align: left;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
  opacity: 1;
}

.input-field::placeholder {
  color: var(--overwatch-neutral-300);
}
</style>
